<template>
  <!-- <div class="wameed-filter-modal  "> -->
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      style="overflow: visible"
  >
    <b-modal
        header-class="py-5 px-5"
        body-class="py-5 px-2"
        id="wameed-modal"
        v-model="show"
        v-b-modal.modal-lg
        content-class="w-full rounded-8"
        dialog-class="wameed-modal-md"

        no-fade
        scrollable
    >
      <template #modal-header>
        <div class="d-flex justify-content-between align-items-center w-100">
          <h4 class="text-medium-20">{{ $t('common.change_password') }}</h4>
          <b-button
              variant="white"
              size="sm"
              class="close"
              @click="show = false"
          >
            <close-icon/>
          </b-button>
        </div>
      </template>
      <!-- <wameed-form refs="" @submitAction="submitJob">
               <template slot="form-content" slot-scope="{ invalid }"> -->
      <div class="py-1">


        <div class="d-flex flex-wrap">
          <b-col cols="12" md="6">
            <text-input
                id="old-password"
                v-model="oldPassword"
                :label="$t('common.old_password')"
                fieldClasses="mb-5"
                inputClasses="text-med-14 text-font-main "
                name="oldPassword"
                rules="required|min:6"
                :placeholder="$t('common.old_password_placeholder')"
                isAppend
                :icon="showOldPassword ? 'eyeon-icon' : 'eyeoff-icon'"
                @onPostIconClick="showOldPassword = !showOldPassword"
                :type="showOldPassword ? 'password' : 'text'"
            />
          </b-col>
        </div>

        <div class="d-flex flex-wrap">
          <b-col cols="12" md="6">
            <text-input
                id="new-password"
                v-model="newPassword"
                :label="$t('common.new_password')"
                fieldClasses="mb-5"
                inputClasses="text-med-14 text-font-main"
                name="newPassword"
                rules="required|min:6"
                :placeholder="$t('common.new_password_placeholder')"
                isAppend
                :icon="showNewPassword ? 'eyeon-icon' : 'eyeoff-icon'"
                @onPostIconClick="showNewPassword = !showNewPassword"
                :type="showNewPassword ? 'password' : 'text'"
            />
          </b-col>

          <b-col cols="12" md="6">

            <text-input
                id="confirm-new-password"
                v-model="confirmNewPassword"
                :label="$t('common.confirm_new_password')"
                fieldClasses="mb-5"
                inputClasses="text-med-14 text-font-main"
                name="confirmNewPassword Password Confirm"
                rules="required|confirmed:newPassword"
                :placeholder="$t('common.new_password_placeholder')"
                isAppend
                :icon="showConfirmPassword ? 'eyeon-icon' : 'eyeoff-icon'"
                @onPostIconClick="showConfirmPassword = !showConfirmPassword"
                :type="showConfirmPassword ? 'password' : 'text'"
            />
          </b-col>
        </div>
      </div>
      <template slot="modal-footer">
        <div class="d-flex justify-content-between align-items-center w-100">
          <wameed-btn
              classes="  text-med-14 text-white rounded-10"
              :title="$t('common.save_edited')"
              :disabled="invalid"
              variant="main"
              @onClick="submitChangedPassword"
          />
          <wameed-btn
              classes="  text-med-14 text-font-secondary rounded-10 "
              :title="$t('common.cancel')"
              type="button"
              variant="gray"
              @onClick="closeModal"
          />
        </div>
      </template>
      <!-- </template>
      </wameed-form> -->
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver} from 'vee-validate';
import {WameedBtn, WameedForm, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import {mapActions} from 'vuex';

export default {
  components: {
    WameedForm,
    WameedBtn,
    TextInput,
    ValidationObserver,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showOldPassword: true,
      showNewPassword: true,
      showConfirmPassword: true,
      oldPassword: null,
      newPassword: null,
      confirmNewPassword: null,
    };
  },
  computed: {

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
          setTimeout(()=>{
            this.oldPassword = this.newPassword = this.confirmNewPassword = null
          },500)
        }
      },
    },
  },
  methods: {
    ...mapActions({
      changeUserPassword: 'auth/changeUserPassword',
    }),
    submitChangedPassword() {
      const data = {
        'current-password': this.oldPassword,
        'password': this.newPassword,
        'password_confirmation': this.confirmNewPassword,
      };
      if (data) {
        this.changeUserPassword(data).then(() => {
          this.show = false;
        });
      }
    },
    closeModal() {
      (this.oldPassword = null),
          (this.newPassword = null),
          (this.confirmNewPassword = null),
          (this.show = false);
    },
  },
};
</script>

