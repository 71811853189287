<template>
  <b-card
      v-if="profileData"
      no-body
      class="cursor-pointer wameed-card"
  >
    <b-card-header class="align-items-start tab-title">
      <h5 class="text-reg-18 text-font-main">
        {{ $t('settings.company_data') }}
      </h5>
    </b-card-header>
    <wameed-form refs="" @submitAction="submit">
      <div  slot-scope="{ invalid }">
        <b-card-body
            class="position-relative py-5 justify-content-between px-5"
        >
          <!-- form -->
          <div class="d-flex flex-wrap py-4">
            <b-col cols="12" md="4">
              <text-input
                  v-model="form.name"
                  :label="$t('settings.profile.form.name')"
                  rules="required"
                  :placeholder="$t('form.text.placeholder')"
              />
            </b-col>

            <b-col cols="12" md="4">
              <text-input
                  v-model="form.email"
                  :label="$t('form.email.label')"
                  name="email"
                  rules="required|email"
                  :placeholder="$t('form.text.placeholder')"
              />
            </b-col>

            <b-col cols="12" md="12">
            </b-col>

            <b-col cols="12" md="4">
              <text-input
                  v-model="form.phone1"
                  :label="$t('form.phone.label')"
                  :placeholder="$t('form.text.placeholder')"
              />
            </b-col>
            <b-col cols="12" md="4">
              <text-input
                  v-model="form.phone2"
                  :label="$t('form.phone.other')"
                  :placeholder="$t('form.text.placeholder')"
              />
            </b-col>
            <b-col cols="12" md="4">
              <text-input
                  v-model="form.phone3"
                  :label="$t('form.phone.other')"
                  :placeholder="$t('form.text.placeholder')"
              />
            </b-col>

            <b-col cols="12" md="12">
              <text-area
                  v-model="form.description"
                  :label="$t('settings.profile.form.description')"
                  inputClasses="text-med-14 text-font-main"
                  name="personalInformation"
                  rules="required"
                  :placeholder="$t('form.text.placeholder')"
                  rows="5"
              />
            </b-col>

            <b-col cols="12" md="12">
              <text-input
                  v-model="form.address"
                  :label="$t('form.address.label')"
                  :placeholder="$t('form.text.placeholder')"
              />
            </b-col>


          </div>
        </b-card-body>
        <b-card-footer class="px-4 py-3">
          <div class="d-flex flex-wrap justify-content-between">
            <div class="px-4 py-3">
              <wameed-btn
                  classes="  text-med-14 text-white rounded-10"
                  :title="$t('common.save_edited')"
                  :disabled="invalid"
                  type="submit"
                  variant="main"
              />
            </div>
            <div class="px-4 py-3">
              <wameed-btn
                  classes="  text-med-14 text-font-secondary rounded-10 "
                  :title="$t('common.cancel')"
                  type="button"
                  variant="gray"
                  @onClick="resetChanges"
              />
            </div>
          </div>
        </b-card-footer>
      </div>
    </wameed-form>
  </b-card>
</template>

<script>

import {mapGetters} from 'vuex';
import {
  WameedBtn,
  WameedForm,
  WameedTextArea as TextArea,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm'


export default {
  components: {
    TextInput,
    WameedBtn,
    WameedForm,
    TextArea,
  },
  data() {
    return {

      form: {
        name: '',
        email: '',
        phone1: '',
        phone2: '',
        phone3: '',
        description: '',
        address: '',
        profile_image: '',
        background_image: ''
      }
    };
  },
  computed: {
    ...mapGetters({
      profileData: 'vendor/settings/profile/getFull',
    }),
  },
  methods: {

    isImgUploaded() {
      if (this.currentImg.length === 0) {
        return true;
      }
      return false;
    },

    setLogoImage(file) {
      this.currentImg = file;
    },

    resetChanges() {
      if (this.profileData) {
        this.currentImg = [this.profileData.provider.image];
        this.serviceProviderName = this.profileData.provider.name;
        this.serviceProviderType = this.profileData.provider.user_type;
        this.email = this.profileData.provider.email;
        this.city = this.profileData.provider.country;
        this.personalInformation = this.profileData.provider.personal_info;
      }
    },

    submit() {
      this.$store.dispatch('vendor/settings/profile/update', this.form).then(() => {
        this.$store.dispatch('vendor/settings/profile/full')
      })
    },
  },
  updated() {
    if (this.profileData) {
      // this.currentImg = [this.profileData.provider.image];
      // this.serviceProviderName = this.profileData.provider.name;
      // this.serviceProviderType = this.profileData.provider.user_type;
      // this.email = this.profileData.provider.email;
      // this.city = this.profileData.provider.country;
      // this.personalInformation = this.profileData.provider.personal_info;
      // this.interests = this.profileData.provider.interests;
    }
  },
};
</script>

