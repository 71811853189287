<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('settings.company_data')"
        :paths="[{title:$t('settings.title'),link:'settings'}]"
    />

    <section class="wameed-dashboard-page-content_body">
      <b-row class="px-4">

        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="cursor-pointer wameed-card py-5 px-5" @click="changePassword = true">
            <div class="d-flex py-1 px-1 align-items-center justify-content-between">
              <h4 class="h-panel__title text-reg-20">
                {{ $t('common.change_password') }}
              </h4>
              <arrow-down-icon class="rotate-90-inverse"/>
            </div>
          </b-card>

          <change-password
              :visible="changePassword"
              @close="changePassword = false"/>
        </b-col>



      </b-row>
    </section>
  </div>
</template>

<script>


import DetailsForm from '@/views/pages/settings/profile/components/DetailsForm.vue';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import ChangePassword from '@/views/pages/settings/profile/components/ChangePassword.vue';

export default {
  components: {

    DetailsForm,
    PageHeader,
    ChangePassword,
  },
  data() {
    return {
      changePassword: false,
    };
  },


  created() {
    this.$store.dispatch('vendor/settings/profile/full')
  },
};
</script>
